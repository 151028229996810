<template>
  <div>
    <b-card
      no-body
    >
      <template #header>
        <h4 class="mr-auto mb-0">
          Maintenance
        </h4>
      </template>

      <b-row>
        <b-col
          cols="12"
          lg="5"
        >
          <div class="px-2 pb-2">
            <h4>Redis</h4>

            <b-row
              align-v="center"
              class="mb-2"
            >
              <b-col
                class="align-middle"
              >
                Ping
                <div v-if="PingResult">
                  {{ PingResult === true? 'Success': 'Failed' }} ({{ transformDate(LastPingResult) }})
                </div>
              </b-col>
              <b-col cols="auto">
                <b-button @click="triggerPingRedis">
                  Trigger
                </b-button>
              </b-col>
            </b-row>
            <b-row
              align-v="center"
              class="mb-2"
            >
              <b-col>Refresh/Clear Cache

                <div v-if="RefreshResult">
                  {{ RefreshResult }} ({{ transformDate(LastRefreshResult) }})
                </div>
              </b-col>
              <b-col cols="auto">
                <b-button @click="triggerRefreshRedis">
                  Trigger
                </b-button>
              </b-col>
            </b-row>

            <b-row
              class="mb-2 mt-3 border-top py-3"
            >
              <b-col>
                <h6>Master Pool Adjustment</h6>
                <b-row>
                  <b-col
                    cols="12"
                    lg="4"
                  >
                    <b-form-group :class="[{ 'has-error': v$.triggerMasterPoolBalanceAdjustmentForm.account_id.$error }]">
                      <b-form-input
                        v-model="triggerMasterPoolBalanceAdjustmentForm.account_id"
                        type="text"
                        :class="[{ 'is-invalid': v$.triggerMasterPoolBalanceAdjustmentForm.account_id.$error }]"
                        placeholder="Account ID"
                      />
                      <template v-for="(error, idx) in v$.triggerMasterPoolBalanceAdjustmentForm.account_id.$errors">
                        <p
                          :key="'error-'+idx"
                          class="small text-danger"
                        >
                          {{ error.$message }}
                        </p>
                      </template>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="5"
                  >
                    <b-form-group>
                      <flat-pickr
                        v-model="rangeDate"
                        placeholder="Select Date Range"
                        class="form-control"
                        :config="{ mode: 'range',
                                   maxDate: new Date().fp_incr(1),
                                   dateFormat: 'd-m-Y',}"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    lg="3"
                  >
                    <b-button @click="triggerAdjustment">
                      Trigger
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row
              class="mb-2 mt-3 border-top py-3"
            >
              <b-col
                cols="12"
                lg="8"
              >
                <h6>Failed Bill Adjustment</h6>
                <b-row>
                  <b-col
                    cols="12"
                    lg="8"
                  >
                    <b-form-group>
                      <b-form-input
                        v-model="failedBillAdjustmentForm.billId"
                        type="text"
                        :class="[{ 'is-invalid': v$.failedBillAdjustmentForm.billId.$error }]"
                        placeholder="Bill ID"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="4"
                  >
                    <b-button @click="triggerFailedBillAdjustment(failedBillAdjustmentForm.billId)">
                      Trigger
                    </b-button>
                  </b-col>
                </b-row>

              </b-col>

            </b-row>
            <b-row
              class="mb-2 mt-3 border-top py-3"
            >
              <b-col>
                <h6>Self Settlement Min Limit Adjustment</h6>
                <b-row>
                  <b-col
                    cols="12"
                    lg="4"
                  >
                    <b-form-group>
                      <b-form-input
                        v-model="selfSettlementForm.id"
                        type="text"
                        :class="[{ 'is-invalid': v$.selfSettlementForm.id.$error }]"
                        placeholder="Account ID"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="8"
                  >
                    <b-form-group>
                      <vue-numeric
                        v-model="selfSettlementForm.amount"
                        :precision="2"
                        :currency="myCurrency"
                        :class="[{ 'is-invalid': v$.selfSettlementForm.amount.$error }]"
                        class="form-control text-right"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-button @click="triggerMinLimitAdjustment(selfSettlementForm.id)">
                      Trigger
                    </b-button>
                  </b-col>
                </b-row>

              </b-col>

            </b-row>

            <b-row
              class="mb-2 mt-3 border-top py-3"
            >
              <b-col>
                <h6>Take out from Payout Pool</h6>
                <b-row>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group>
                      <b-form-input
                        v-model="takeOutFromPrefundForm.sender_account_id"
                        type="text"
                        :class="[{ 'is-invalid': v$.takeOutFromPrefundForm.sender_account_id.$error }]"
                        placeholder="Account ID"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group>
                      <vue-numeric
                        v-model="takeOutFromPrefundForm.transfer_amount"
                        :precision="2"
                        :currency="myCurrency"
                        :class="[{ 'is-invalid': v$.takeOutFromPrefundForm.transfer_amount.$error }]"
                        class="form-control text-right"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >

                    <b-form-group>
                      <b-form-input
                        v-model="takeOutFromPrefundForm.transfer_description"
                        type="text"
                        :class="[{ 'is-invalid': v$.takeOutFromPrefundForm.transfer_description.$error }]"
                        placeholder="Transfer Description"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-button @click="triggerTakeOutFromPrefund">
                      Trigger
                    </b-button>
                  </b-col>
                </b-row>

              </b-col>

            </b-row>
            <b-row
              class="mb-2 mt-3 border-top py-3"
            >
              <b-col>
                <h6>Refund To Payout Pool</h6>
                <b-row>
                  <b-col
                    cols="12"
                    lg="4"
                  >

                    <b-form-group>
                      <b-form-input
                        v-model="refundToPrefundForm.refund_account_id"
                        type="text"
                        :class="[{ 'is-invalid': v$.refundToPrefundForm.refund_account_id.$error }]"
                        placeholder="Account ID"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="4"
                  >

                    <b-form-group>
                      <vue-numeric
                        v-model="refundToPrefundForm.amount_"
                        :precision="2"
                        :currency="myCurrency"
                        :class="[{ 'is-invalid': v$.refundToPrefundForm.amount_.$error }]"
                        class="form-control text-right"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="4"
                  >

                    <b-form-group>
                      <b-form-input
                        v-model="refundToPrefundForm.invoice_no"
                        type="text"
                        :class="[{ 'is-invalid': v$.refundToPrefundForm.invoice_no.$error }]"
                        placeholder="Invoice No"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-button @click="triggerRefundToPrefund">
                      Trigger
                    </b-button>
                  </b-col>
                </b-row>

              </b-col>

            </b-row>

            <b-row
              class="mb-2 mt-3 border-top py-3"
            >
              <b-col>
                <h6>Update Payout Pool Settings</h6>

                <b-row>
                  <b-col
                    cols="12"
                  >

                    <b-form-group>
                      <b-form-checkbox
                        v-model="updatePrefundSettingForm.is_prefund_delay_enabled"
                        style="height: 50px"
                        class="mr-0"
                        switch
                        inline
                      /> Enable Payout Pool Delay
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    lg="4"
                  >

                    <b-form-group>
                      <b-form-input
                        v-model="updatePrefundSettingForm.accountId"
                        type="text"
                        :class="[{ 'is-invalid': v$.updatePrefundSettingForm.accountId.$error }]"
                        placeholder="Account ID"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="4"
                  >

                    <b-form-group>
                      <vue-numeric
                        v-model="updatePrefundSettingForm.prefund_delay_duration"
                        class="form-control text-right"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="4"
                  >

                    <b-form-group>
                      <v-select
                        v-model="updatePrefundSettingForm.prefund_delay_unit"
                        placeholder="Delay Unit"
                        :reduce="options => options.id"
                        :options="$store.getters['systemConstants/getSystemConstantsByKey']('delay_unit')"
                        label="type"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-button @click="updatePrefundSettings">
                      Trigger
                    </b-button>
                  </b-col>
                </b-row>

              </b-col>

            </b-row>
          </div>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'

import vSelect from 'vue-select'

import flatPickr from 'vue-flatpickr-component'
import { useVuelidate } from '@vuelidate/core'
import {
  integer, minValue,
  required,
} from '@vuelidate/validators'
import { BFormCheckbox } from 'bootstrap-vue'
import humaniseMixin from '@/common/humanise.mixin'

export default {
  components: { BFormCheckbox, flatPickr, vSelect },
  mixins: [humaniseMixin],
  data() {
    return {
      rangeDate: null,
      triggerMasterPoolBalanceAdjustmentForm: {
        account_id: '',
      },
      failedBillAdjustmentForm: {
        billId: '',
      },
      selfSettlementForm: {
        id: null,
        amount: 0,
      },
      refundToPrefundForm: {
        refund_account_id: null,
        amount_: 0,
        invoice_no: '',
      },
      takeOutFromPrefundForm: {
        transfer_amount: 0,
        sender_account_id: null,
        transfer_description: 'Settlement - ',
      },
      updatePrefundSettingForm: {
        accountId: null,
        prefund_delay_duration: 0,
        prefund_delay_unit: 0,
        is_prefund_delay_enabled: false,
      },
    }
  },

  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      triggerMasterPoolBalanceAdjustmentForm: {
        account_id: { required, integer },
      },
      failedBillAdjustmentForm: {
        billId: { required, integer },
      },
      selfSettlementForm: {
        id: { required, integer },
        amount: { required, integer, minValue: minValue(1) },
      },
      takeOutFromPrefundForm: {
        transfer_amount: { required, minValue: minValue(1) },
        sender_account_id: { required, integer },
        transfer_description: { required },
      },
      refundToPrefundForm: {
        amount_: { required, minValue: minValue(1) },
        refund_account_id: { required, integer },
        invoice_no: { required },
      },
      updatePrefundSettingForm: {
        accountId: { required },
        prefund_delay_duration: { required, minValue: minValue(1) },
        prefund_delay_unit: { required },
        is_prefund_delay_enabled: { required },
      },
    }
  },
  computed: {
    prefundDelayUnitOpts() {
      if (this.$store.getters['systemConstants/getSystemConstants']) {
        const list = this.$store.getters['systemConstants/getSystemConstantsByKey']('delay_unit')
        if (list) {
          return list
        } return []
      } return []
    },
    processedDateRange() {
      let dateRange = this.rangeDate
      if (dateRange) {
        dateRange = dateRange.split(' to ')
        if (dateRange.length > 1) {
          return {
            startDate: dateRange[0],
            endDate: dateRange[1],
          }
        }
        return {
          startDate: dateRange[0],
          endDate: dateRange[0],
        }
      }
      return this.defaultDateRange
    },
    PingResult() {
      const result = this.$store.getters['maintenance/pingRedisResult']
      if (result && result.data) {
        return result.data.success_ping
      }
      return null
    },
    LastPingResult() {
      const result = this.$store.getters['maintenance/pingRedisResult']
      if (result && result.data) {
        return result.talk_to_server_before
      }
      return null
    },
    RefreshResult() {
      const result = this.$store.getters['maintenance/refreshRedisResult']
      if (result && result.data) {
        return result.data.clear_data
      }
      return null
    },
    LastRefreshResult() {
      const result = this.$store.getters['maintenance/refreshRedisResult']
      if (result && result.data) {
        return result.talk_to_server_before
      }
      return null
    },
  },
  async mounted() {
    this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Maintenance' },
    ]
    await this.$store.dispatch('systemConstants/getSystemConstants')
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
  },
  methods: {
    async triggerPingRedis() {
      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: 'Are you sure?',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('maintenance/pingRedis')
        }
      })
    },
    async triggerRefreshRedis() {
      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: 'Are you sure?',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('maintenance/refreshRedis')
        }
      })
    },
    async triggerFailedBillAdjustment() {
      const valid = await this.v$.failedBillAdjustmentForm.$validate()

      if (!valid) {
        return
      }

      const htmlContent = `
        <div>
          <h4>Adjust Failed Bill?</h4>
          <div class="d-flex justify-content-between mt-2  mb-1">
              <h6 class="mb-0"><strong>Account ID</strong></h6>
              <h6 class="mb-0">${this.failedBillAdjustmentForm.billId}</h6>
          </div>
        </div>
      `

      const payload = {
        billId: this.failedBillAdjustmentForm.billId,
      }
      console.log(payload)

      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: htmlContent,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('maintenance/triggerFailedBillAdjustment', payload).then(res => {
            if (res.data.response_code === 2000) {
              this.$swal.fire({
                title: 'Successfully Triggered',
                text: 'Failed Bill Adjustment Triggered',
                icon: 'success',
              })
            } else {
              const desc = `<h5>Description</h5><p>${res.data.description}</p><h5>Breakdown</h5><p>${res.data.breakdown_errors}</p>`
              this.$swal.fire({
                title: `Error - ${res.data.response_code}`,
                html: desc,
                icon: 'error',
              })
            }
          })
        }
      })
    },
    async triggerMinLimitAdjustment() {
      const valid = await this.v$.selfSettlementForm.$validate()

      if (!valid) {
        return
      }

      const payload = {
        amount: this.selfSettlementForm.amount,
        id: this.selfSettlementForm.id,
      }
      console.log(payload)

      const htmlContent = `
        <div>
          <h4>Change Min Self Settlement Limit?</h4>
          <div class="d-flex justify-content-between mt-2  mb-1">
              <h6 class="mb-0"><strong>Account ID</strong></h6>
              <h6 class="mb-0">${this.selfSettlementForm.id}</h6>
          </div>
          <div class="d-flex justify-content-between mb-1">
              <h6 class="mb-0"><strong>Amount</strong></h6>
              <h6 class="mb-0">${this.myCurrency} ${this.selfSettlementForm.amount}</h6>
          </div>
        </div>
      `

      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: htmlContent,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('maintenance/changeMinLimitSelfSettlement', payload).then(res => {
            if (res.data.response_code === 2000) {
              this.$swal.fire({
                title: 'Successfully Triggered',
                text: 'Limit Changed',
                icon: 'success',
              })
            } else {
              const desc = `<h5>Description</h5><p>${res.data.description}</p><h5>Breakdown</h5><p>${res.data.breakdown_errors}</p>`
              this.$swal.fire({
                title: `Error - ${res.data.response_code}`,
                html: desc,
                icon: 'error',
              })
            }
          })
        }
      })
    },
    async triggerAdjustment() {
      const valid = await this.v$.triggerMasterPoolBalanceAdjustmentForm.$validate()

      if (!valid) {
        return
      }
      const payload = {
        account_id: this.triggerMasterPoolBalanceAdjustmentForm.account_id,
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
      }
      console.log(payload)

      const htmlContent = `
        <div>
          <h4>Trigger Master Pool Adjustment?</h4>
          <div class="d-flex justify-content-between mt-2  mb-1">
              <h6 class="mb-0"><strong>Account ID</strong></h6>
              <h6 class="mb-0">${this.triggerMasterPoolBalanceAdjustmentForm.account_id}</h6>
          </div>
        </div>
      `

      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: htmlContent,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('maintenance/triggerAdjustmentMasterPoolBalance', payload).then(res => {
            if (res.data.response_code === 2000) {
              this.$swal.fire({
                title: 'Successfully Triggered',
                text: 'Master Pool Manual Adjustment Triggered',
                icon: 'success',
              })
            } else {
              const desc = `<h5>Description</h5><p>${res.data.description}</p><h5>Breakdown</h5><p>${res.data.breakdown_errors}</p>`
              this.$swal.fire({
                title: `Error - ${res.data.response_code}`,
                html: desc,
                icon: 'error',
              })
            }
          })
        }
      })
    },

    async triggerRefundToPrefund() {
      const valid = await this.v$.refundToPrefundForm.$validate()

      if (!valid) {
        return
      }

      // refundToPrefundForm: {
      //   refund_account_id: null,
      //       amount_: 0,
      //       invoice_no: '',
      // },

      const htmlContent = `
        <div>
          <h4>Refund to Payout Pool?</h4>
          <div class="d-flex justify-content-between mt-2  mb-1">
              <h6 class="mb-0"><strong>Transfer Amount</strong></h6>
              <h6 class="mb-0">${this.myCurrency} ${this.refundToPrefundForm.amount_}</h6>
          </div>
          <div class="d-flex justify-content-between  mb-1">
              <h6  class="mb-0"><strong>Account ID</strong></h6>
              <h6 class="mb-0">${this.refundToPrefundForm.refund_account_id}</h6>
          </div>
          <div class="d-flex flex-wrap justify-content-between mb-1">
              <h6 class="mb-0"><strong>Invoice No</strong></h6>
              <h6 class="mb-0">${this.refundToPrefundForm.invoice_no}</h6>
          </div>
        </div>
      `

      const payload = {
        ...this.refundToPrefundForm,
      }
      console.log(payload)

      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: htmlContent,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          console.log(payload)
          this.$store.dispatch('maintenance/adminRefundToPrefund', payload).then(res => {
            if (res.data.response_code === 2123) {
              this.$swal.fire({
                title: 'Successfully Triggered',
                text: 'Refund to Payout Pool Successfully Triggered',
                icon: 'success',
              })
            } else {
              const desc = `<h5>Description</h5><p>${res.data.description}</p><h5>Breakdown</h5><p>${res.data.breakdown_errors}</p>`
              this.$swal.fire({
                title: `Error - ${res.data.response_code}`,
                html: desc,
                icon: 'error',
              })
            }
          })
        }
      })
    },
    async triggerTakeOutFromPrefund() {
      const valid = await this.v$.takeOutFromPrefundForm.$validate()

      if (!valid) {
        return
      }

      const payload = {
        ...this.takeOutFromPrefundForm,
      }
      console.log(payload)
      // takeOutFromPrefundForm: {
      //   transfer_amount: 0,
      //       sender_account_id: null,
      //       transfer_description: 'Settlement - ',
      // },
      const htmlContent = `
        <div>
          <h4>Take out from Prefund?</h4>
          <div class="d-flex justify-content-between mt-2  mb-1">
              <h6 class="mb-0"><strong>Transfer Amount</strong></h6>
              <h6 class="mb-0">${this.myCurrency} ${this.takeOutFromPrefundForm.transfer_amount}</h6>
          </div>
          <div class="d-flex justify-content-between  mb-1">
              <h6  class="mb-0"><strong>Account ID</strong></h6>
              <h6 class="mb-0">${this.takeOutFromPrefundForm.sender_account_id}</h6>
          </div>
          <div class="d-flex flex-wrap justify-content-between mb-1">
              <h6 class="mb-0"><strong>Transfer Description</strong></h6>
              <h6 class="mb-0">${this.takeOutFromPrefundForm.transfer_description}</h6>
          </div>
        </div>
      `

      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: htmlContent,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          console.log(payload)
          this.$store.dispatch('maintenance/adminTakeOutToPrefund', payload).then(res => {
            if (res.data.response_code === 2000) {
              this.$swal.fire({
                title: 'Successfully Triggered',
                text: 'Takeout from Payout Pool Successfully Triggered',
                icon: 'success',
              })
            } else {
              const desc = `<h5>Description</h5><p>${res.data.description}</p><h5>Breakdown</h5><p>${res.data.breakdown_errors}</p>`
              this.$swal.fire({
                title: `Error - ${res.data.response_code}`,
                html: desc,
                icon: 'error',
              })
            }
          })
        }
      })
    },
    async updatePrefundSettings() {
      const valid = await this.v$.updatePrefundSettingForm.$validate()

      if (!valid) {
        return
      }

      const payload = {
        ...this.updatePrefundSettingForm,
      }
      console.log(payload)

      const htmlContent = `
        <div>
          <h4>Update Prefund Setting?</h4>
          <div class="d-flex justify-content-between mt-2 mb-1">
              <h6  class="mb-0"><strong>Account ID</strong></h6>
              <h6 class="mb-0">${this.updatePrefundSettingForm.accountId}</h6>
          </div>
          <div class="d-flex justify-content-between mb-1">
              <h6 class="mb-0"><strong>Delay Enabled</strong></h6>
              <h6 class="mb-0"> ${this.updatePrefundSettingForm.is_prefund_delay_enabled}</h6>
          </div>
          <div>
            <div class="d-flex flex-wrap justify-content-between mb-1">
                <h6 class="mb-0"><strong>Delay Duration</strong></h6>
                <h6 class="mb-0">${this.updatePrefundSettingForm.prefund_delay_duration}</h6>
            </div>
            <div class="d-flex flex-wrap justify-content-between mb-1">
                <h6 class="mb-0"><strong>Delay Unit</strong></h6>
                <h6 class="mb-0">${this.updatePrefundSettingForm.prefund_delay_unit === 1 ? 'Seconds' : this.updatePrefundSettingForm.prefund_delay_unit === 2 ? 'Minutes' : 'Hours'}</h6>
            </div>
          </div>
        </div>
      `

      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: htmlContent,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          console.log(payload)
          this.$store.dispatch('maintenance/adminUpdatePrefundSettings', payload).then(res => {
            if (res.data.response_code === 2000) {
              this.$swal.fire({
                title: 'Successfully Updated',
                text: 'Payout Pool Setting Updated',
                icon: 'success',
              })
            } else {
              const desc = `<h5>Description</h5><p>${res.data.description}</p><h5>Breakdown</h5><p>${res.data.breakdown_errors}</p>`
              this.$swal.fire({
                title: `Error - ${res.data.response_code}`,
                html: desc,
                icon: 'error',
              })
            }
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
